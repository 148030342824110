import React from "react"
import Navbar from "../components/Navbar.jsx"
import Footer from "./Footer.jsx"

const Layout = ({ location, title, children }) => {
  return (
    <div>
      <header style={{ marginTop: "70px" }}>
        <Navbar />
      </header>
      <main
        style={{
          minHeight: "84.2vh",
          fontFamily: "Montserrat, sans-serif",
        }}
      >
        {children}
      </main>
      <Footer />
    </div>
  )
}

export default Layout
