import React from "react"
import styled from "styled-components"
import { ContentContainer } from "./Theme.jsx"
import { IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin } from "react-icons/io"

const FooterContainer = styled.footer`
  font-family: Montserrat, sans-serif;
  background: #323437;
  color: white;
  padding: 20px;
  width: 100%;

  h3 {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    margin: 0 0 20px 0;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
  }

  a {
    color: white;
    text-decoration: none;
    box-shadow: none;
  }
`

const FooterIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  svg {
    height: 42px;
    width: 42px;
    margin-right: 8px;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

const StyledImage = styled.img`
  height: 40px;
  width: auto;
  margin: 0 0 auto 0;
`

export default function Footer() {
  return (
    <FooterContainer>
      <ContentContainer>
        <FooterIconWrapper>
          <div>
            <a
              href="https://www.instagram.com/joao.de.morais.photos/"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoInstagram />
            </a>
            <a
              href="https://www.facebook.com/joao.m.demorais"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoFacebook />
            </a>
            <a
              href="https://se.linkedin.com/in/jo%C3%A3o-manuel-f-de-morais-a235947"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoLinkedin />
            </a>
          </div>
          <div>
            <a
              href="https://galileocommission.org/report/"
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: "0.75rem" }}
            >
              <StyledImage
                src={"/galileo_commision_logo.png"}
                alt="Galileo Comission"
                style={{ height: "38px" }}
              />
            </a>
            <a
              href="https://www.naturefirstphotography.org/"
              target="_blank"
              rel="noreferrer"
            >
              <StyledImage
                src={"/nature_first.png"}
                alt="Nature first proud member"
              />
            </a>
          </div>
        </FooterIconWrapper>
        <p>
          Copyright &copy; {new Date().getFullYear()} João de Morais. All Rights
          Reserved.
        </p>
        <p>
          Website by{" "}
          <a
            href="https://walidsodki.com"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline" }}
          >
            Walid Sodki
          </a>
          . Made in Stockholm, 2020-2021.
        </p>
      </ContentContainer>
    </FooterContainer>
  )
}
