import styled, { keyframes } from "styled-components"

// Keyframes animations needs to be on top
const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    transform: ${props => props.transform && props.transform};
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`

/* 
  Usage: wrap component you want to animate with <FadeInAnimation>
  Example:
   <FadeInAnimation 
      transform="translate(-25%, 0)" 
      duration="0.5s"
      delay="0.2s"
      >
      <CardComponent />
    </FadeInAnimation>
*/

export const FadeInAnimation = styled.div`
  animation: ${fadeInAnimation} ${props => props.duration && props.duration}
    ease forwards;
  animation-delay: ${props =>
    props.delay ? Object.values(props.delay) : "0s"};
  transform: ${props => props.transform && props.transform};
  opacity: 0;
`

export const ContentContainer = styled.div`
  font-family: Montserrat, sans-serif;
  max-width: ${props => (props.maxWidth ? props.maxWidth : "1140px")};
  margin: ${props => (props.marginY ? props.marginY : "0")} auto;
  padding: ${props => (props.padding ? props.padding : "0 15px")};

  a {
    color: black;
  }
`
