import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { IoIosMenu, IoIosClose } from "react-icons/io"
import Signature from "../images/joao_signature.png"

const Container = styled.header`
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  width: 100%;
  background-color: white;
  height: ${props => (props.height ? props.height : "70px")};
  border-bottom: 1px solid #e5e9ed;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
`

const InnerWrapper = styled.div`
  display: none;
  @media (min-width: 768px) {
    max-width: 1140px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const NavLink = styled(Link)`
  color: #232323;
  margin: 0 15px;
  transition: all 0.2s;
  box-shadow: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  &:hover {
    box-shadow: 0 1px 0 0 #232323;
  }

  a {
    box-shadow: none;
  }
`

const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    height: 100%;
  }
`
const StyledIoIosMenu = styled(IoIosMenu)`
  height: 46px;
  width: 46px;
  margin-left: 16px;
  cursor: pointer;
`

const StyledIoIosClose = styled(IoIosClose)`
  height: 60px;
  width: 60px;
  margin-left: 10px;
  cursor: pointer;
`

const MobileMenu = styled.div`
  display: none;
  @media (max-width: 768px) {
    position: absolute;
    z-index: 2;
    height: 92vh;
    width: 50vw;
    min-width: 200px;
    max-width: 60vw;
    background: white;
    padding: 15px;

    display: flex;
    flex-direction: column;

    ${NavLink} {
      font-size: 20px;
    }
    opacity: 0.85;
  }
`

const SignatureImage = styled.img`
  height: 22px;
  margin: 10px 0 0 0;
  &:hover {
    box-shadow: none;
  }

  @media (min-width: 900px) {
    height: 32px;
  }
`

export default function Navbar() {
  const [navbarToggled, setNavbarToggled] = useState(false)

  return (
    <Container>
      <InnerWrapper>
        <NavLink to="/" style={{ boxShadow: `none` }}>
          <SignatureImage
            src={Signature}
            alt="Joao de Morais"
            onContextMenu={e => e.preventDefault()}
          />
        </NavLink>
        <div>
          <NavLink to="/" activeClassName="active">
            Home
          </NavLink>
          <NavLink to="/about/" activeClassName="active">
            About
          </NavLink>
          <NavLink to="/photography/" activeClassName="active">
            Photography
          </NavLink>
          <NavLink to="/pages/" activeClassName="active">
            Pages
          </NavLink>
          <NavLink to="/blog" activeClassName="active">
            Blog
          </NavLink>
          <NavLink to="/contact" activeClassName="active">
            Contact
          </NavLink>
        </div>
      </InnerWrapper>
      <MobileWrapper>
        {navbarToggled ? (
          <StyledIoIosClose onClick={() => setNavbarToggled(!navbarToggled)} />
        ) : (
          <StyledIoIosMenu onClick={() => setNavbarToggled(!navbarToggled)} />
        )}
      </MobileWrapper>
      {navbarToggled && (
        <MobileMenu>
          <NavLink to="/" activeClassName="active">
            Home
          </NavLink>
          <NavLink to="/about/" activeClassName="active">
            About
          </NavLink>
          <NavLink to="/photography/" activeClassName="active">
            Photography
          </NavLink>
          <NavLink to="/pages/" activeClassName="active">
            Pages
          </NavLink>
          <NavLink to="/blog" activeClassName="active">
            Blog
          </NavLink>
          <NavLink to="/contact" activeClassName="active">
            Contact
          </NavLink>
        </MobileMenu>
      )}
    </Container>
  )
}
